import type { ProductSpecifications } from "@10xdev/types/models/ProductSpecifications";
import { css } from "@emotion/react";

import Grid from "../../Grid";
import Image from "../../Image";
import Text from "../../Text";
import Callout from "./Callout";
import Link from "./Link";

const ProductOverview = (overviewProps: ProductSpecifications) => {
  const { callout, description, image, name, productLink, storeLink } =
    overviewProps;
  return (
    <Grid gap={"1.125rem"} gridAutoRows={"max-content"}>
      {image ? (
        <Image
          alt={name}
          css={css`
            justify-self: center;
            min-height: 235px;
          `}
          src={image}
        />
      ) : null}
      <Text as={"p"} color={"midgray"} size={"small"}>
        {description}
      </Text>
      {callout ? (
        <Callout
          list={callout.list}
          product={productLink}
          store={storeLink}
          title={callout.title}
        />
      ) : null}
      {productLink && !callout ? (
        <Link linkText={productLink.label} linkUrl={productLink.url} />
      ) : null}
    </Grid>
  );
};

export default ProductOverview;
