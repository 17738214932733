import { css } from "@emotion/react";
import type { ReactNode } from "react";

import Flex from "../../Flex";
import Text from "../../Text";

interface Props {
  children: ReactNode;
  className?: string;
  label?: string;
}

const CompareRow = ({ children, className, label }: Props) => {
  return (
    <div
      className={className}
      css={css`
        padding: 2.5rem 0 1.5rem;
        width: 100%;
      `}
    >
      {label ? (
        <Text
          as={"h3"}
          css={css`
            grid-column: 1 / span 3;
            margin-bottom: 2rem;
          `}
          size={"xlarge"}
          weight={"semibold"}
        >
          {label}
        </Text>
      ) : null}
      <Flex
        css={css`
          > div:nth-of-type(3) {
            @media (max-width: 900px) {
              display: none;
            }
          }
          > div {
            flex: 1 1 50%;
          }
        `}
        gap={{ base: "1rem", tabletPortrait: "8%" }}
      >
        {children}
      </Flex>
    </div>
  );
};

export default CompareRow;
