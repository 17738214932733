import { ASSAY_GROUPS } from "./constants";
import type {
  AssayGroup,
  Assays,
  AssaySlug,
  Option,
  OptionGroup,
  SelectOrder,
} from "./types";

export const getAssay = (assays: Assays, slug: AssaySlug) =>
  assays.find((item) => item.slug === slug);

export const getAssayGroup = (activeSegment: AssayGroup, assays: Assays) =>
  ASSAY_GROUPS[activeSegment].map((slug: AssaySlug) => getAssay(assays, slug));

export const getPlatformTag = (id: string) => {
  const name = id.split("-").join(" ");
  const platformColors: Record<string, string> = {
    // TODO: Add chromium and visium colors to design tokens
    ["chromium single cell"]: "#194D99",
    ["visium spatial"]: "#2B977D",
    ["xenium in situ"]: "#7060AA", // cnv product color
  };
  return {
    color: platformColors[name],
    name,
  };
};

export const selectGroups: SelectOrder[] = [
  {
    label: "Chromium Single Cell",
    options: [
      "chromium-single-cell-gene-expression-flex",
      "chromium-single-cell-gene-expression",
      "chromium-single-cell-immune-profiling",
      "chromium-single-cell-atac",
      "chromium-single-cell-multiome",
    ],
  },
  {
    label: "Visium Spatial",
    options: [
      "visium-hd-spatial-gene-expression",
      "visium-spatial-gene-expression-ffpe",
      "visium-spatial-gene-expression",
    ],
  },
  {
    label: "Xenium In Situ",
    options: ["xenium-in-situ-gene-expression"],
  },
];

export const getSelectOptions = (
  selectGroups: SelectOrder[],
  products: Assays,
): OptionGroup[] => {
  return selectGroups.map((group) => {
    const options: Option[] = group.options.reduce<Option[]>(
      (matchedOptions, slug) => {
        const product = products.find((product) => product.slug === slug);
        if (product) {
          matchedOptions.push({ label: product.name, value: product.slug });
        }
        return matchedOptions;
      },
      [],
    );

    return {
      label: group.label,
      options,
    };
  });
};
