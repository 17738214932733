import { layoutBannerHeight, mediaLaptop } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Branding from "../../Branding";
import Navigation from "../../Navigation";
import { useRouter } from "next/router";

interface Props {
  /** Specifies "light" (blue text) or "dark" (white text). */
  mode?: "light" | "dark";
  maxWidth?: string;
}

/**
 * A layout container for navigation and 10x branding.
 */
const Banner: FunctionComponent<Props> = ({ maxWidth, mode = "dark" }) => {
  const { locale } = useRouter();
  return (
    <div
      css={css`
        align-items: center;
        box-sizing: border-box;
        display: flex;
        gap: 1.5rem;
        height: ${layoutBannerHeight};
        margin: 0 auto;
        max-width: ${maxWidth};
        padding: 1rem 0;
        width: 100%;

        @media (min-width: ${mediaLaptop}) {
          gap: 3.5rem;
        }
      `}
    >
      <Branding height={"2rem"} mode={mode} />

      <Navigation locale={locale} mode={mode} />
    </div>
  );
};

export default Banner;
