import type { ProductCapability } from "@10xdev/types/models/ProductCapability";
import { css } from "@emotion/react";

import Grid from "../../Grid";
import Image from "../../Image";
import Text from "../../Text";
import Link from "./Link";

const List = (listProps: ProductCapability) => {
  const { description, image, link, linkText, name } = listProps;
  return (
    <Grid alignItems={"center"} gap={".75rem"} gridTemplateColumns={"62px 1fr"}>
      {image ? <Image alt={name} src={image} /> : null}
      <Grid>
        <Text
          as={"span"}
          css={css`
            line-height: 1.1;
          `}
          size={"small"}
          weight={"regular"}
        >
          {name}
        </Text>
        <Text as={"span"} color={"midgray"} size={"xxsmall"} weight={"regular"}>
          {description}
        </Text>
        {link ? <Link linkText={linkText} linkUrl={link} /> : null}
      </Grid>
    </Grid>
  );
};

export default List;
