import type { ProductCapability } from "@10xdev/types/models/ProductCapability";
import { css } from "@emotion/react";

import Grid from "../../Grid";
import Image from "../../Image";
import Text from "../../Text";
import Link from "./Link";

const ProductFeature = (featureProps: ProductCapability) => {
  const { description, image, link, linkText, name } = featureProps;
  return (
    <Grid
      css={css`
        padding-top: 0.75rem;
      `}
      gap={".75rem"}
      gridAutoRows={"max-content"}
    >
      {image ? <Image alt={name} src={image} /> : null}
      {name ? (
        <Text as={"span"} size={"medium"} weight={"medium"}>
          {name}
        </Text>
      ) : null}
      <Text as={"p"} color={"midgray"} size={"small"}>
        {description}
      </Text>
      {link ? <Link linkText={linkText} linkUrl={link} /> : null}
    </Grid>
  );
};

export default ProductFeature;
